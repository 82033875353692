<template v-if="$api.vouchers && $api.texts.vouchers">
	<div class="vouchers">
		<div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 order-2 order-lg-1">
          <div class="voucher-images">
            <div class="voucher-image"><img src="../../../assets/images/voucher.webp" loading="lazy" alt="Voucher"></div>
            <div class="voucher-image">
              <img src="../../../assets/images/voucher.webp" loading="lazy" alt="Voucher">
              <span class="voucher-value">
                ~{{selectedVoucher?.value}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7 order-1 order-lg-2 text-column">
          <h2 class="smaller-heading right font-brown">
            Vouchery
          </h2>
          <div v-html="$api.texts.vouchers.raw.split('<hr>')[0]"></div>

          <select v-model="voucherSelectValue" class="pretty-select">
            <option disabled :value="null">Vyberte hodnotu</option>
            <option v-for="voucher in $api.vouchers" :key="voucher.image" :value="voucher">
              {{ voucher.value }}
            </option>
          </select>

          <div class="voucher-buy-wrapper">
            <strong class="price">
              {{selectedVoucher?.value}}
            </strong>
            <Button color="brown" @click.native="toggleCartModal(true)">
              Koupit
            </Button>
          </div>
        </div>

        <div v-html="$api.texts.vouchers.raw.split('<hr>')[1]" class="col-12 order-3 bottom-column"></div>
			</div>
		</div>

		<Modal :open="cartModalVisible" @close="toggleCartModal" :loading="sending">
			<template v-slot:title>
				Jakým způsobem si přejete voucher doručit?
			</template>
			<template v-slot:body>
				<div class="col-sm-6 d-flex flex-column voucher-delivery-option">
					<Button color="black" @click.native="addToCart('post')">
						Poštou
					</Button>
          <span>
            + {{ $price($api.cart.voucherPostPackagingPrice) }} za dárkové balení (obálka se stužkou)
          </span>
				</div>
				<div class="col-sm-6">
					<Button color="black" @click.native="addToCart('email')">
						E-mailem
					</Button>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>

	import Button from "../../components/Button";
	import {EventBus} from "../../../event-bus";
	import Modal from "../../components/Modal";

	export default {
		name: "Vouchers",
		components: {Modal, Button},
		data() {
			return {
				selectedVoucher: null,
        voucherSelectValue: null,
        cartModalVisible: false,
				sending: false,
			};
		},
		methods: {
			toggleCartModal(visible = false, resetVoucher = false) {
        this.cartModalVisible = visible;

        if (resetVoucher) {
          this.voucherSelectValue = null;
        }
			},

			addToCart(method) {
				this.sending = true;
				this.$api.addToCart(this.selectedVoucher, "voucher", 1, {
					shipping: method
				})
					.then(() => {
						EventBus.$emit("flash", "Voucher úspěšně přidán do košíku");
						this.toggleCartModal(false, true);
					})
					.catch(() => {
						EventBus.$emit("flash", "Voucher se nepodařilo přidat do košíku", "error");
					})
					.finally(() => {
						this.sending = false;
					});
			},

      setDefaultVoucherValue() {
        const vouchers = Object.values(this.$api.vouchers);
        this.selectedVoucher = vouchers.find((voucher) =>
            voucher.value_raw === 1000
        ) ?? vouchers[0];
      }
		},
    watch: {
      voucherSelectValue(value) {
        if (value !== null) {
          this.selectedVoucher = value;
        } else {
          this.setDefaultVoucherValue();
        }
      },
    },
    created() {
      this.setDefaultVoucherValue();
    }
	}
</script>